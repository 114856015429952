import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import FaqArea from '../components/FAQ/FaqArea'
import Footer from '../components/_App/Footer'

import Data from '../components/Data/Data'

const FaqPage = () => {
  return (
    <Layout>
      <Seo title="Frequently Asked Questions" />

      <TopHeader />

      <Navbar />

      <FaqArea
        description="We at InnoAge always believe in Transparency with our clients & employees, please check
            list of frequently asked questions. Feel free to ask if this doesn't help."
        faq1={Data.faqData1}
        faq2={Data.faqData2}
      />

      <Footer />
    </Layout>
  )
}

export default FaqPage
